@import url('https://fonts.googleapis.com/css?family=Ultra&display=swap');
@import url('https://fonts.googleapis.com/css?family=IM+Fell+DW+Pica+SC&display=swap');
@import './theme.scss';

* {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
}

body {
  margin: 0;
  height: 90vh;
  font-family: 'IM Fell DW Pica SC', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $old-yellow;
  color: $black;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input:focus, textarea:focus, button:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: $black;
  &:visited {
    color: $black;
  }
}

.mobile-only {
  @media screen and (min-width: 901px) {
    display:none;
  }
}

.desktop-only {
  @media screen and (max-width: 900px) {
    display:none;
  }
}