@import url(https://fonts.googleapis.com/css?family=Ultra&display=swap);
@import url(https://fonts.googleapis.com/css?family=IM+Fell+DW+Pica+SC&display=swap);
* {
  margin: 0;
  padding: 0;
  font-size: 62.5%; }

body {
  margin: 0;
  height: 90vh;
  font-family: 'IM Fell DW Pica SC', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #D3B67C;
  color: #202020; }

#root {
  display: flex;
  width: 100%;
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input:focus, textarea:focus, button:focus {
  outline: none; }

a {
  text-decoration: none;
  color: #202020; }
  a:visited {
    color: #202020; }

@media screen and (min-width: 901px) {
  .mobile-only {
    display: none; } }

@media screen and (max-width: 900px) {
  .desktop-only {
    display: none; } }

h1, h2 {
  text-align: center;
  font-family: 'Ultra', serif;
  font-size: 3rem; }

.app {
  width: 120rem;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .app .paper-info {
    display: flex;
    justify-content: space-between;
    font-size: 3rem;
    padding: 0 0.5rem; }

hr {
  margin: 0.5rem 0;
  border: 1px solid #202020; }

.hero-news {
  margin-bottom: 2rem; }
  @media screen and (max-width: 1201px) {
    .hero-news {
      padding: 0 1rem; } }
  @media screen and (min-width: 1201px) {
    .hero-news {
      padding: 0 10rem; } }
  .hero-news h1 {
    flex-wrap: wrap;
    font-size: 6rem;
    text-align: center; }
    @media screen and (max-width: 600px) {
      .hero-news h1 {
        font-size: 4rem; } }

.home {
  display: flex;
  font-size: 3rem;
  text-align: justify;
  flex-direction: column; }
  .home .col {
    display: flex;
    padding: 0 1rem;
    flex-wrap: wrap; }
  @media screen and (min-width: 901px) {
    .home .form {
      flex: 1 1; } }
  @media screen and (max-width: 900px) {
    .home .form {
      flex-basis: 100%;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      text-align: center; } }
  .home .form p {
    font-size: 2rem;
    flex: 1 1; }
  .home .form input {
    flex: 3 1;
    font-size: 10rem;
    max-width: 25rem;
    margin: auto; }
  .home .result {
    position: relative; }
    @media screen and (min-width: 901px) {
      .home .result {
        flex: 2 1; } }
    @media screen and (max-width: 900px) {
      .home .result {
        flex-basis: 100%;
        margin-bottom: 2rem;
        height: 25rem; } }
    .home .result textarea {
      width: 100%;
      font-size: 3rem;
      font-family: 'IM Fell DW Pica SC', serif; }
    .home .result span {
      font-size: 5rem;
      position: absolute; }
    .home .result .first-quote {
      top: -2.5rem;
      left: -1.5rem;
      -webkit-transform: scaleX(-1) rotateZ(-35deg);
              transform: scaleX(-1) rotateZ(-35deg); }
    .home .result .second-quote {
      right: 0;
      bottom: -4.5rem;
      -webkit-transform: rotateZ(30deg);
              transform: rotateZ(30deg); }
  .home .buttons {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 901px) {
      .home .buttons {
        flex: 1 1; } }
    @media screen and (max-width: 900px) {
      .home .buttons {
        flex-basis: 100%;
        margin-bottom: 2rem;
        height: 10rem; } }
    .home .buttons button {
      flex: 2 1;
      width: 100%;
      font-size: 2rem; }
    .home .buttons .spacer {
      flex: 1 1; }

.bolsonaro-picture {
  margin-top: 2rem;
  flex: 1 1; }
  .bolsonaro-picture img {
    width: 40rem;
    height: auto; }
    @media screen and (max-width: 1200px) {
      .bolsonaro-picture img {
        margin: auto; } }

.bolsonaro-statement {
  flex: 2 1;
  margin-top: 2rem;
  margin: auto; }
  .bolsonaro-statement h1 {
    font-size: 5rem; }

input {
  border: 1px solid #202020;
  background: transparent;
  text-align: center;
  font-family: 'IM Fell DW Pica SC', serif; }

.textarea {
  background: transparent;
  border: 1px solid #202020;
  resize: none;
  padding: 1rem;
  text-align: justify; }

::-webkit-scrollbar {
  width: 0.5rem; }

::-webkit-scrollbar-track {
  background: #D3B67C; }

::-webkit-scrollbar-thumb {
  background: #202020; }

.button {
  background: transparent;
  border: 1px solid #202020;
  font-family: 'IM Fell DW Pica SC', serif;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }
  .button:hover {
    cursor: pointer;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px); }
  .button:active {
    cursor: pointer;
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }

.footer {
  justify-self: end;
  margin-top: auto; }
  .footer .footer-info {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
    font-size: 3rem; }
    .footer .footer-info a {
      font-size: 2rem; }
    .footer .footer-info .developer {
      text-align: left; }
    .footer .footer-info .github {
      text-align: right; }

