@import './theme.scss';

h1, h2 {
  text-align: center;
  font-family: 'Ultra', serif;
  font-size: 3rem;
}

.app {
  width: 120rem;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  .paper-info {
    display: flex;
    justify-content: space-between;
    font-size: 3rem;
    padding: 0 0.5rem;
  }
}

hr {
  margin: 0.5rem 0;
  border: 1px solid $black;
}

.hero-news {

  @media screen and (max-width: 1201px) {
    padding: 0 1rem;
  }

  @media screen and (min-width: 1201px) {
    padding: 0 10rem;
  }

  margin-bottom: 2rem;
  h1 {
    flex-wrap: wrap;
    font-size: 6rem;
    text-align: center;
    @media screen and (max-width: 600px) {
      font-size: 4rem;
    }
  }
}