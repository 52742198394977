@import '../../theme.scss';

.home {
    display: flex;
    font-size: 3rem;
    text-align: justify;
    flex-direction: column;
    .col {
        display: flex;
        padding: 0 1rem;
        flex-wrap: wrap;
        @media screen {
            
        }
    }

    .form {
        @media screen and (min-width: 901px) {
            flex: 1;
        }
        @media screen and (max-width: 900px) {
            flex-basis: 100%;
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        p {
            font-size: 2rem;
            flex: 1;
        }
        input {
            flex: 3;
            font-size: 10rem;
            max-width: 25rem;
            margin: auto;
        }

    }
    
    .result {
        @media screen and (min-width: 901px) {
            flex: 2;
        }
        @media screen and (max-width: 900px) {
            flex-basis: 100%;
            margin-bottom: 2rem;
            height: 25rem;
        }
        position: relative;
        textarea {
            width: 100%;
            font-size: 3rem;
            font-family: 'IM Fell DW Pica SC', serif;
        }

        span {
            font-size: 5rem;
            position: absolute;
        }

        .first-quote {
            top: -2.5rem;
            left: -1.5rem;
            transform: scaleX(-1) rotateZ(-35deg);
        }

        .second-quote {
            right: 0;
            bottom: -4.5rem;
            transform: rotateZ(30deg);
        }
    }
    
    .buttons { 
        @media screen and (min-width: 901px) {
            flex: 1;
        }
        @media screen and (max-width: 900px) {
            flex-basis: 100%;
            margin-bottom: 2rem;
            height: 10rem;
        }
        display: flex;
        flex-direction: column;
        button {
            flex: 2;
            width: 100%;
            font-size: 2rem;
        }

        .spacer {
            flex: 1;
        }
    }
}

.bolsonaro-picture {
    margin-top: 2rem;
    flex: 1;
    img {
        width: 40rem;
        height: auto;
        @media screen and (max-width: 1200px) {
            margin: auto;
        }
    }
}

.bolsonaro-statement {
    flex: 2;
    margin-top: 2rem;
    margin: auto;
    h1 {
        font-size: 5rem;
    }
}